import Vue from 'vue';
import VueRouter, { RouteConfig, RouteRecordPublic } from 'vue-router';
import {
  acxessAdministrationFeatureIds,
  acxessConversationsFeatureIds,
  acxessFeatureAccess,
  acxessPermissionSet,
  acxessProductIds,
  acxessRespondersFeatureIds,
  acxessUserSignature,
  acxessValidatePasswordResetTokenResult,
  acxessValidateSignupTokenResult,
  matchPermissionSet,
} from '@/models/acxess';
import cloneDeep from 'lodash.clonedeep';
import {
  getAccountTimezone,
  getRoles,
  getUserSignature,
  validateForgotPasswordResetToken,
  validateSignupToken,
} from '@/services/acxess';
import { logAcxessException, logType } from '@/services/logger';
import globalStore from '@/store/index';
import { isTokenExpired } from '@/store/acxessAuth';
import { blinkTitle, loginRedirectUrl } from '@/utils';
import {
  authenticateWithGenesysToken, getGenesysExternalAgentId, getGenesysLineIds, getInternalConversationId,
} from '@/services/acxess-publicApi';
import genesysAuthRequest from '@/models/genesys/genesysAuthRequest';
import genesysAuthResponse from '@/models/genesys/genesysAuthResponse';
import genesysState from '@/models/genesys/genesysState';
import { authenticateWithDfoToken } from '@/services/platform-nice-dfo';
import { getAgentId } from '@/services/acxess-conversations';
import { getAccountCustomDomain } from '@/services/platform-short-links';
import { customDomain } from '@/models/short-links';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
});

function getQueryParamKeyCaseInsensitive(obj:any, keys:string[]):string | undefined {
  if (obj == null) {
    return undefined;
  }
  for (let i = 0; i < keys.length; i++) {
    const keySearch = Object.keys(obj).find((x:string) => x.toLowerCase() === keys[i].toLowerCase());
    if (keySearch != null) {
      return keySearch;
    }
  }
  return undefined;
}

export async function setAgent(agentId?: string): Promise<void> {
  if (agentId) {
    globalStore.dispatch('auth/agentId', { agentId });
  } else {
    try {
      globalStore.dispatch('auth/agentId', { agentId: await getAgentId() });
    } catch (e) {
      logAcxessException(logType.Error, e);
    }
  }
}

export async function bootstrapLogin(token: string): Promise<void> {
  const promises = [];

  globalStore.dispatch('auth/login', { token });
  promises.push(getRoles() as Promise<any>);
  promises.push(getAccountTimezone() as Promise<any>);
  promises.push(getUserSignature() as Promise<acxessUserSignature>);
  promises.push(getAccountCustomDomain() as Promise<customDomain>);
  const results: any = await Promise.all(promises);
  globalStore.dispatch('auth/roles', { roles: results[0] });
  globalStore.dispatch('auth/accountTimezone', { accountTimezone: results[1] });
  globalStore.dispatch('auth/userSignature', { userSignature: results[2] });
  globalStore.dispatch('auth/accountCustomDomain', { accountCustomDomain: results[3] });
}

// TODO: Extract all Genesys specific code to a separate file.
export function buildGenesysAuthUrl(environment: string, authRequest: genesysAuthRequest) : string {
  const baseAuthUrl = `https://login.${environment}/oauth/authorize`;
  const authUrl = new URL(baseAuthUrl);
  authUrl.searchParams.append('client_id', authRequest.client_id);
  authUrl.searchParams.append('response_type', authRequest.response_type);
  authUrl.searchParams.append('redirect_uri', authRequest.redirect_uri);
  authUrl.searchParams.append('state', authRequest.state);
  return authUrl.toString();
}

export function initiateGenesysImplicitAuth(state: genesysState, redirectUri: string) : void {
  const authRequest: genesysAuthRequest = {
    client_id: process.env.VUE_APP_GENESYS_OAUTH_CLIENT_ID as string,
    response_type: 'token',
    redirect_uri: redirectUri,
    state: JSON.stringify(state),
  };

  const authUrl = buildGenesysAuthUrl(state.environment, authRequest);

  logAcxessException(logType.Info, 'Textel - Redirecting to Genesys Auth URL');

  if (state.usePopupAuth) {
    logAcxessException(logType.Error, 'Textel - Popup Auth not implemented yet. Attempting standard auth.');
  }

  try {
    window.location.href = authUrl;
  } catch (e) {
    logAcxessException(logType.Error, `Error redirecting to Genesys Auth URL: ${authUrl}`);
    logAcxessException(logType.Error, e);
  }
}

export async function bootstrapGenesysLogin(authData: genesysAuthResponse): Promise<boolean | null> {
  if (authData.access_token != null && authData.state != null) {
    try {
      const state = JSON.parse(authData.state) as genesysState;
      const token: string = await authenticateWithGenesysToken(authData.access_token, state.environment);
      if (token != null) {
        await bootstrapLogin(token);
        globalStore.dispatch('integrations/genesysAccessToken', { genesysAccessToken: authData.access_token });
        const agentResponse = await getGenesysExternalAgentId(authData.access_token, state.environment);
        await setAgent();
        globalStore.dispatch('integrations/externalAgentId', { externalAgentId: agentResponse?.externalAgentId });
        return true;
      }
    } catch (e) {
      logAcxessException(logType.Error, e);
    }
  }
  return false;
}

export function getgenesysStateFromQuery(query: any): genesysState | null {
  const qsConversationIdKey = getQueryParamKeyCaseInsensitive(query, ['conversationId']);
  const qsLangTagKey = getQueryParamKeyCaseInsensitive(query, ['langTag']);
  const qsEnvironmentKey = getQueryParamKeyCaseInsensitive(query, ['environment']);
  const qsUsePopupAuthKey = getQueryParamKeyCaseInsensitive(query, ['usePopupAuth']);
  const qsUseImplicitAuthKey = getQueryParamKeyCaseInsensitive(query, ['useImplicitAuth']);

  if (qsConversationIdKey != null && query[qsConversationIdKey] != null
  && qsEnvironmentKey != null && query[qsEnvironmentKey] != null
  && qsLangTagKey != null && query[qsLangTagKey] != null
  && qsUsePopupAuthKey != null && query[qsUsePopupAuthKey] != null) {
    const state: genesysState = {
      environment: query[qsEnvironmentKey] as string,
      langTag: query[qsLangTagKey] as string,
      usePopupAuth: query[qsUsePopupAuthKey] as string === 'true',
      conversationId: query[qsConversationIdKey] as string,
      useImplicitAuth: qsUseImplicitAuthKey != null && query[qsUseImplicitAuthKey] != null ? query[qsUseImplicitAuthKey] as string === 'true' : undefined,
    };

    return state;
  }

  return null;
}

export function getChannelStateFromQuery(query: any): any {
  const qsToken = getQueryParamKeyCaseInsensitive(query, ['token']);
  const qsBrandId = getQueryParamKeyCaseInsensitive(query, ['brandId']);
  const qsApiHost = getQueryParamKeyCaseInsensitive(query, ['apiHost']);
  const qsUserId = getQueryParamKeyCaseInsensitive(query, ['userId']);
  const qsBackUrl = getQueryParamKeyCaseInsensitive(query, ['backUrl']);
  const qsChannelId = getQueryParamKeyCaseInsensitive(query, ['channelId']);

  if (qsToken && query[qsToken]
  && qsBrandId && query[qsBrandId]
  && qsApiHost && query[qsApiHost]
  && qsUserId && query[qsUserId]
  && qsBackUrl && query[qsBackUrl]) {
    const state: any = {
      apiHost: query[qsApiHost] as string,
      backUrl: query[qsBackUrl] as string,
      brandId: query[qsBrandId] as number,
      token: query[qsToken] as string,
      niceUserId: query[qsUserId] as number,
      channelId: qsChannelId ? query[qsChannelId] as string : null,
    };

    return state;
  }

  return null;
}

export async function handleGenesysPopoutQueryParams(to: any, next: any, state: genesysState) : Promise<void> {
  if (state.useImplicitAuth === false) {
    logAcxessException(logType.Info, 'Textel Conversation Popout - Implicit Auth disabled, using platform auth');
    next(`/popout?gcid=${state.conversationId}`);
  } else {
    const genesysAccessToken: string | null = globalStore.getters['integrations/genesysAccessToken'];
    if (genesysAccessToken != null) {
      logAcxessException(logType.Info, 'Textel Conversation Popout - Existing Genesys access token found in store');
      const authData: genesysAuthResponse = { access_token: genesysAccessToken, state: JSON.stringify(state) };
      const bootstrapLoginSuccess = await bootstrapGenesysLogin(authData);
      if (bootstrapLoginSuccess) {
        logAcxessException(logType.Info, 'Textel Conversation Popout - Genesys bootstrap login success');
        const internalConversationId = await getInternalConversationId(state.conversationId);
        if (internalConversationId != null) {
          next(`/popout?cid=${internalConversationId}`);
          return;
        }
      }
      logAcxessException(logType.Warning, 'Textel Conversation Popout - Genesys bootstrap login failed');
    }
    logAcxessException(logType.Info, 'Textel Conversation Popout - Initiating Implicit Auth');
    initiateGenesysImplicitAuth(state, `${window.location.origin}${to.path}`);
  }
}

export async function handleGenesysNewMessageQueryParams(to: any, next: any, state: genesysState) : Promise<void> {
  if (state.useImplicitAuth === false) {
    logAcxessException(logType.Info, 'Textel New Conversation App - Implicit Auth disabled, using platform auth');
    next(`/conversations/new-message?pid=${acxessProductIds.Genesys}`);
    return;
  }
  const genesysAccessToken: string | null = globalStore.getters['integrations/genesysAccessToken'];
  if (genesysAccessToken != null) {
    logAcxessException(logType.Info, 'Textel New Conversation App - Existing Genesys access token found in store');
    const authData: genesysAuthResponse = { access_token: genesysAccessToken, state: JSON.stringify(state) };
    const bootstrapLoginSuccess = await bootstrapGenesysLogin(authData);
    if (bootstrapLoginSuccess) {
      logAcxessException(logType.Info, 'Textel New Conversation App - Genesys bootstrap login success');
      const allowedLinesResponse = await getGenesysLineIds(genesysAccessToken, state.environment);
      if (allowedLinesResponse != null) {
        globalStore.dispatch('integrations/allowedLines', { allowedLines: allowedLinesResponse.lineIds as Array<string> });
      } else {
        logAcxessException(logType.Error, 'Textel New Conversation App - Could not retrieve allowed lines from Genesys');
        globalStore.dispatch('integrations/allowedLines', { allowedLines: [] });
      }
      next(`/conversations/new-message?pid=${acxessProductIds.Genesys}`);
      return;
    }
    logAcxessException(logType.Warning, 'Textel New Conversation App - Genesys bootstrap login failed');
  }
  logAcxessException(logType.Info, 'Textel New Conversation App - Initiating Implicit Auth');
  initiateGenesysImplicitAuth(state, `${window.location.origin}${to.path}`);
}

export function tryParseGenesysHashParams(hash: string): genesysAuthResponse | null {
  const params = new URLSearchParams(hash.slice(1));
  const hashObject : { [key: string] : string} = {};
  params.forEach((value, key) => {
    hashObject[key] = value;
  });

  if (hashObject.error != null) {
    return hashObject as genesysAuthResponse;
  }
  if (hashObject.access_token != null && hashObject.state != null && hashObject.expires_in != null && hashObject.token_type != null) {
    return {
      access_token: decodeURIComponent(hashObject.access_token),
      state: decodeURIComponent(hashObject.state),
      expires_in: decodeURIComponent(hashObject.expires_in),
      token_type: decodeURIComponent(hashObject.token_type),
    };
  }
  logAcxessException(logType.Error, `Textel - Error Parsing Genesys hash params: ${hash}`);
  return null;
}

export async function handleGenesysPopoutHashParams(to: any, next: any, authData: genesysAuthResponse) : Promise<void> {
  if (authData.state != null && authData.access_token != null) {
    logAcxessException(logType.Info, 'Textel Conversation Popout - Successfully parsed implicit oauth params');
    const bootstrapLoginSuccess = await bootstrapGenesysLogin(authData);
    if (bootstrapLoginSuccess) {
      logAcxessException(logType.Info, 'Textel Conversation Popout - Genesys bootstrap login success');
      const state = JSON.parse(authData.state) as genesysState;
      const internalConversationId = await getInternalConversationId(state.conversationId);
      if (internalConversationId != null) {
        next(`/popout?cid=${internalConversationId}`);
        return;
      }
    }
    logAcxessException(logType.Error, 'Textel Conversation Popout - Genesys bootstrap login failed');
  } else if (authData.error != null) {
    logAcxessException(logType.Error, `Textel Conversation Popout - Genesys Oauth error: ${authData.error} - ${authData.error_description}`);
  } else {
    logAcxessException(logType.Error, 'Textel Conversation Popout - Failed to parse implicit oauth params');
  }
  next({
    path: '/404',
    replace: true,
  });
}

export async function handleGenesysNewMessageHashParams(to: any, next: any, authData: genesysAuthResponse) : Promise<void> {
  if (authData.state != null && authData.access_token != null) {
    logAcxessException(logType.Info, 'Textel New Conversation App - Successfully parsed implicit oauth params');
    const bootstrapLoginSuccess = await bootstrapGenesysLogin(authData);
    if (bootstrapLoginSuccess) {
      logAcxessException(logType.Info, 'Textel New Conversation App - Genesys bootstrap login success');
      const state = JSON.parse(authData.state) as genesysState;
      const allowedLinesResponse = await getGenesysLineIds(authData.access_token, state.environment);
      if (allowedLinesResponse != null) {
        globalStore.dispatch('integrations/allowedLines', { allowedLines: allowedLinesResponse.lineIds as Array<string> });
      } else {
        logAcxessException(logType.Error, 'Textel New Conversation App - Could not retrieve allowed lines from Genesys');
        globalStore.dispatch('integrations/allowedLines', { allowedLines: [] });
      }
      next(`/conversations/new-message?pid=${acxessProductIds.Genesys}`);
      return;
    }
    logAcxessException(logType.Error, 'Textel New Conversation App - Genesys bootstrap login failed');
  } else if (authData.error != null) {
    logAcxessException(logType.Error, `Textel New Conversation App - Genesys Oauth error: ${authData.error} - ${authData.error_description}`);
  } else {
    logAcxessException(logType.Error, 'Textel New Conversation App - Failed to parse implicit oauth params');
  }
  next({
    path: '/404',
    replace: true,
  });
}

export async function handlePopoutQueryParams(to: any, next: any) : Promise<void> {
  const state = getgenesysStateFromQuery(to.query);
  if (state != null) {
    try {
      await handleGenesysPopoutQueryParams(to, next, state);
    } catch (e) {
      logAcxessException(logType.Error, 'Textel Conversation Popout - Error handling query params');
      logAcxessException(logType.Error, e);
      next({
        path: '/404',
        replace: true,
      });
    }
  }
}

export async function handleNewMessageQueryParams(to: any, next: any) : Promise<void> {
  const state = getgenesysStateFromQuery(to.query);
  if (state != null) {
    try {
      await handleGenesysNewMessageQueryParams(to, next, state);
    } catch (e) {
      logAcxessException(logType.Error, 'Textel New Conversation App - Error handling query params');
      logAcxessException(logType.Error, e);
      next({
        path: '/404',
        replace: true,
      });
    }
  }
}

export async function handleDfoChannelQueryParams(to: any, next: any): Promise<void> {
  const state = getChannelStateFromQuery(to.query);

  if (state) {
    try {
      const token: string = await authenticateWithDfoToken(state.token, state.brandId, state.niceUserId, state.apiHost);
      if (token != null) {
        await bootstrapLogin(token);
        router.push({
          name: to.name,
          params: {
            backUrl: state.backUrl,
            brandId: state.brandId,
            niceUserId: state.niceUserId,
            channelId: state.channelId,
          },
        });
      } else {
        next({
          path: '/404',
          replace: true,
        });
      }
    } catch (e) {
      logAcxessException(logType.Error, 'Textel Create Channel - Error handling query params');
      logAcxessException(logType.Error, e);
      next({
        path: '/404',
        replace: true,
      });
    }
  }
}

export async function handlePopoutHashParams(to: any, next: any) : Promise<void> {
  const genesysAuthParams = tryParseGenesysHashParams(to.hash);
  if (genesysAuthParams != null) {
    try {
      await handleGenesysPopoutHashParams(to, next, genesysAuthParams);
    } catch (e) {
      logAcxessException(logType.Error, 'Textel Conversation Popout - Error handling hash params');
      logAcxessException(logType.Error, e);
      next({
        path: '/404',
        replace: true,
      });
    }
  }
}

export async function handleNewMessageHashParams(to: any, next: any) : Promise<void> {
  const genesysAuthParams = tryParseGenesysHashParams(to.hash);
  if (genesysAuthParams != null) {
    try {
      await handleGenesysNewMessageHashParams(to, next, genesysAuthParams);
    } catch (e) {
      logAcxessException(logType.Error, 'Textel New Conversation App - Error handling hash params');
      logAcxessException(logType.Error, e);
      next({
        path: '/404',
        replace: true,
      });
    }
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue'),
    meta: {
      pageTitle: 'Welcome',
      showNav: true,
      showHeader: true,
      secured: true,
    },
  },
  {
    path: '/conversations',
    name: 'Conversations',
    component: () => import(/* webpackChunkName: "conversations" */ '../views/Conversations.vue'),
    meta: {
      pageTitle: 'Conversations',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Conversations,
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        rail: {
          icon: 'mdi-comment-processing-outline',
          title: 'Conversations',
        },
      },
    },
  },
  {
    path: '/scheduled-messages',
    name: 'ScheduledMessages',
    component: () => import(/* webpackChunkName: "scheduled-messages" */ '../views/ScheduledMessages.vue'),
    meta: {
      pageTitle: 'Scheduled Messages',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Conversations,
    },
  },
  {
    path: '/blast',
    name: 'Blast',
    component: () => import(/* webpackChunkName: "blast" */ '../views/Blast.vue'),
    meta: {
      pageTitle: 'Blast',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Blast,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        rail: {
          icon: 'mdi-bullhorn-outline',
          title: 'Blast',
        },
      },
    },
  },
  {
    path: '/blast/create',
    name: 'Create Campaign',
    component: () => import(/* webpackChunkName: "create-campaign" */ '../views/CreateCampaign.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Blast,
      access: [
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
    },
    beforeEnter(to, from, next) {
      // because data will not be reloaded properly on refresh, we redirect them to blast
      if (from.name == null || from.name === to.name) {
        next('/blast');
      } else {
        next();
      }
    },
    props: true,
  },
  {
    path: '/blast/:campaignId/results',
    name: 'Campaign Results',
    component: () => import(/* webpackChunkName: "campaign-results" */ '../views/CampaignResults.vue'),
    meta: {
      pageTitle: 'Campaign Results',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Blast,
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
    },
    props: true,
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      pageTitle: 'User Management',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Administration,
      feature: acxessAdministrationFeatureIds.UserManagement,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
    },
  },
  {
    path: '/manage-account',
    name: 'ManageAccount',
    component: () => import(/* webpackChunkName: "users" */ '../views/Account.vue'),
    meta: {
      pageTitle: 'Manage Account',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Administration,
      feature: acxessAdministrationFeatureIds.AccountManagement,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "loginSignupReset" */ '../views/Login.vue'),
    meta: {
      pageTitle: 'Login',
      showNav: false,
      showHeader: false,
    },
    beforeEnter: async (to, from, next) => {
      const qsTokenKey = getQueryParamKeyCaseInsensitive(to.query, ['token']);
      if (qsTokenKey != null && to.query[qsTokenKey] != null) {
        await bootstrapLogin(to.query[qsTokenKey] as string);
        await setAgent();
      }
      // check to see if user is already logged in
      if (globalStore.getters['auth/isLoggedIn']) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import(/* webpackChunkName: "my-profile" */ '../views/MyProfile.vue'),
    meta: {
      pageTitle: 'My Profile',
      showNav: true,
      showHeader: true,
      secured: true,
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
    meta: {
      pageTitle: 'Contacts',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Administration,
      feature: acxessAdministrationFeatureIds.ContactManagement,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        header: {
          title: 'Contacts',
          parent: 'Contacts',
        },
      },
    },
  },
  {
    path: '/contact-lists',
    name: 'ContactLists',
    component: () => import(/* webpackChunkName: "contactlists" */ '../views/ContactLists.vue'),
    meta: {
      pageTitle: 'Contact Lists',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Administration,
      feature: acxessAdministrationFeatureIds.ContactManagement,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        header: {
          title: 'Contact Lists',
          parent: 'Contacts',
        },
      },
    },
  },
  {
    path: '/contact-import-history',
    name: 'ContactImportHistory',
    component: () => import(/* webpackChunkName: "contactImportHistory" */ '../views/ContactImportHistory.vue'),
    meta: {
      pageTitle: 'Contact Import History',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Administration,
      feature: acxessAdministrationFeatureIds.ContactManagement,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        header: {
          title: 'Contact Import History',
          parent: 'Contacts',
        },
      },
    },
  },
  {
    path: '/keywords',
    name: 'Keywords',
    component: () => import(/* webpackChunkName: "keywords" */ '../views/Keywords.vue'),
    meta: {
      pageTitle: 'Keywords',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Responders,
      feature: acxessRespondersFeatureIds.KeywordResponders,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        header: {
          title: 'Keywords',
          parent: 'Responders',
        },
      },
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    beforeEnter: async (to, from, next) => {
      // This route should only be accessible if a valid reset token is provided.
      const qsEmailKey = getQueryParamKeyCaseInsensitive(to.query, ['email']);
      const qsPasswordResetToken = getQueryParamKeyCaseInsensitive(to.query, ['passwordresettoken']);
      if (qsEmailKey != null && qsPasswordResetToken != null) {
        try {
          const result:acxessValidatePasswordResetTokenResult = await validateForgotPasswordResetToken(to.query[qsEmailKey].toString(), to.query[qsPasswordResetToken].toString());
          if (result.isValid) {
            next();
          } else {
            next('/login');
          }
        } catch (error) {
          next('/login');
          logAcxessException(logType.Error, error);
        }
      } else {
        next('/login');
      }
    },
    meta: {
      pageTitle: 'Reset Password',
      showHeader: false,
      showNavigation: false,
    },
    component: () => import(/* webpackChunkName: "loginSignupReset" */ '../views/ResetPassword.vue'),
    props: (route) => ({
      email: route.query[getQueryParamKeyCaseInsensitive(route.query, ['email'])!],
      passwordResetToken: route.query[getQueryParamKeyCaseInsensitive(route.query, ['passwordresettoken'])!],
    }),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      pageTitle: 'Forgot Password',
      showHeader: false,
      showNavigation: false,
    },
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue'),
  },
  {
    path: '/autoReplies',
    name: 'Auto-Replies',
    component: () => import(/* webpackChunkName: "autoReplies" */ '../views/AutoReplies.vue'),
    meta: {
      pageTitle: 'Auto-Replies',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Responders,
      feature: acxessRespondersFeatureIds.AutoReplies,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        header: {
          title: 'Auto-Replies',
          parent: 'Responders',
        },
      },
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    beforeEnter: async (to, from, next) => {
      // This route should only be accessible if a valid reset token is provided
      const qsEmailKey = getQueryParamKeyCaseInsensitive(to.query, ['email']);
      const qsSignupTokenKey = getQueryParamKeyCaseInsensitive(to.query, ['signuptoken']);
      if (qsEmailKey != null && qsSignupTokenKey != null) {
        try {
          const result:acxessValidateSignupTokenResult = await validateSignupToken(to.query[qsEmailKey].toString(), to.query[qsSignupTokenKey].toString());
          if (result.success === true) {
            next();
          } else {
            next('/login');
          }
        } catch (error) {
          next('/login');
          logAcxessException(logType.Error, error);
        }
      } else {
        next('/login');
      }
    },
    meta: {
      pageTitle: 'Sign-Up',
      showHeader: false,
      showNavigation: false,
    },
    component: () => import(/* webpackChunkName: "loginSignupReset" */ '../views/Signup.vue'),
    props: (route) => ({
      email: route.query[getQueryParamKeyCaseInsensitive(route.query, ['email'])!],
      signupToken: route.query[getQueryParamKeyCaseInsensitive(route.query, ['signuptoken'])!],
    }),
  },
  {
    path: '/lines',
    name: 'Lines',
    component: () => import(/* webpackChunkName: "lines" */ '../views/Lines.vue'),
    meta: {
      pageTitle: 'Line Management',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Administration,
      feature: acxessAdministrationFeatureIds.LineManagement,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        header: {
          title: 'Lines',
          icon: 'mdi-phone',
        },
      },
    },
  },
  {
    path: '/link-management',
    name: 'ShortLinks',
    component: () => import(/* webpackChunkName: "shortLinks" */ '../views/ShortLinks.vue'),
    meta: {
      pageTitle: 'Link Management',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Administration,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
    },
  },
  {
    path: '/bot-settings',
    name: 'Bot Settings',
    component: () => import(/* webpackChunkName: "ringCentral" */ '../views/BotSettings.vue'),
    meta: {
      pageTitle: 'Bot Settings',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.ChatBot,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      nav: {
        rail: {
          icon: '$capacity-icon',
          title: 'Bot Settings',
        },
      },
    },
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: () => import(/* webpackChunkName: "reporting" */ '../views/Reporting.vue'),
    meta: {
      pageTitle: 'Reporting',
      showNav: true,
      showHeader: true,
      product: acxessProductIds.Reporting,
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        rail: {
          icon: 'mdi-chart-bar',
          title: 'Reporting',
        },
      },
    },
  },
  {
    path: '/reporting/history',
    name: 'Report History',
    component: () => import(/* webpackChunkName: "reporting" */ '../views/Reporting.vue'),
    meta: {
      pageTitle: 'Report History',
      showNav: true,
      showHeader: true,
      product: acxessProductIds.Reporting,
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
    },
  },
  {
    path: '/reporting/report/:rid/view',
    name: 'Report Viewer',
    component: () => import(/* webpackChunkName: "reportViewer" */ '../views/ReportViewer.vue'),
    meta: {
      pageTitle: 'Report Viewer',
      showNav: true,
      showHeader: true,
      product: acxessProductIds.Reporting,
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
    },
  },
  {
    path: '/response-templates',
    name: 'Response Templates',
    component: () => import(/* webpackChunkName: "responseTemplates" */ '../views/ResponseTemplates.vue'),
    meta: {
      pageTitle: 'Response Templates',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.Responders,
      feature: acxessRespondersFeatureIds.ResponseTemplates,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Admin',
        'Standard',
        'Support',
      ],
      nav: {
        header: {
          title: 'Response Templates',
          parent: 'Responders',
        },
      },
    },
  },
  {
    path: '/ringcentral',
    name: 'Ring Central',
    component: () => import(/* webpackChunkName: "ringCentral" */ '../views/RingCentral.vue'),
    meta: {
      pageTitle: 'Ring Central',
      showNav: true,
      showHeader: true,
      secured: true,
      product: acxessProductIds.RingCentral,
    },
  },
  {
    path: '/popout',
    name: 'Popout',
    component: () => import(/* webpackChunkName: "popout" */ '../views/Popout.vue'),
    meta: {
      pageTitle: 'Textel Chat',
      showNav: false,
      showHeader: false,
      secured: true,
      product: acxessProductIds.Conversations,
      roles: [
        'Integration',
        'Admin',
        'Support',
      ],
      handleQueryParams: handlePopoutQueryParams,
      handleHashParams: handlePopoutHashParams,
    },
  },
  {
    path: '/conversations/new-group-message',
    name: 'New Group Message',
    component: () => import(/* webpackChunkName: "newGroupMessage" */ '../views/NewGroupMessage.vue'),
    meta: {
      pageTitle: 'Textel New Group Message',
      showNav: false,
      showHeader: false,
      secured: true,
      product: acxessProductIds.Conversations,
      feature: acxessConversationsFeatureIds.GroupMessaging,
      access: [
        acxessFeatureAccess.View,
        acxessFeatureAccess.ViewAndChange,
        acxessFeatureAccess.ViewAndChangeAndExecute,
        acxessFeatureAccess.ViewAndExecute,
      ],
      roles: [
        'Integration',
        'Admin',
        'Support',
      ],
    },
  },
  {
    path: '/conversations/new-message',
    name: 'New Message',
    component: () => import(/* webpackChunkName: "newMessage" */ '../views/NewMessage.vue'),
    meta: {
      pageTitle: 'Textel New Message',
      showNav: false,
      showHeader: false,
      secured: true,
      product: acxessProductIds.Conversations,
      roles: [
        'Integration',
        'Admin',
        'Support',
      ],
      handleQueryParams: handleNewMessageQueryParams,
      handleHashParams: handleNewMessageHashParams,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
    meta: {
      pageTitle: 'Page Not Found',
      showNav: false,
      showHeader: false,
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/two-factor/setup',
    name: 'Two Factor Setup',
    component: () => import(/* webpackChunkName "TwoFactorSetup */ '../views/TwoFactorSetup.vue'),
    meta: {
      pageTitle: 'Two Factor Setup',
      showNav: false,
      showHeader: false,
      secured: true,
    },
  },
  {
    path: '/channelcreate',
    name: 'Channel Create',
    component: () => import(/* webpackChunkName "ChannelCreate */ '../views/ChannelCreate.vue'),
    meta: {
      pageTitle: 'Channel Create',
      showNav: false,
      showHeader: false,
      secured: true,
      handleQueryParams: handleDfoChannelQueryParams,
    },
    props: true,
  },
  {
    path: '/channeldelete',
    name: 'Channel Delete',
    component: () => import(/* webpackChunkName "ChannelDelete */ '../views/ChannelDelete.vue'),
    meta: {
      pageTitle: 'Channel Delete',
      showNav: false,
      showHeader: false,
      secured: true,
      handleQueryParams: handleDfoChannelQueryParams,
    },
    props: true,
  },
  {
    path: '/channelreconnect',
    name: 'Channel Reconnect',
    component: () => import(/* webpackChunkName "ChannelReconnect */ '../views/ChannelReconnect.vue'),
    meta: {
      pageTitle: 'Channel Reconnect',
      showNav: false,
      showHeader: false,
      secured: true,
      handleQueryParams: handleDfoChannelQueryParams,
    },
    props: true,
  },
];

router.addRoutes(routes);

// utility functions for matching routes and permissions
function routeProductMatch(routeMeta:any):boolean {
  const permissions:acxessPermissionSet = {
    productId: routeMeta.product,
  };
  if (routeMeta.feature != null && routeMeta.access != null) {
    permissions.featureId = routeMeta.feature;
    permissions.accessLevels = routeMeta.access;
  }
  return matchPermissionSet(permissions, globalStore);
}

function routeRoleMatch(allowedRoles:Array<string>):boolean {
  const userRole = globalStore.getters['auth/session'].UserRole;
  const matchedRole = allowedRoles.find((x:string) => x.toLowerCase() === userRole.toLowerCase());
  return matchedRole != null;
}

function allowedRoutes(isHeaderNav:boolean):Array<RouteRecordPublic> {
  const result:Array<RouteRecordPublic> = [];
  const allRoutes = router.getRoutes();
  for (let i = 0; i < routes.length; i++) {
    if (allRoutes[i].meta != null
        && allRoutes[i].meta.nav != null
        && ((isHeaderNav && allRoutes[i].meta.nav.header != null)
        || (!isHeaderNav && allRoutes[i].meta.nav.rail != null))) {
      // is this route restricted to product access?
      if (allRoutes[i].meta.product == null
        || (allRoutes[i].meta.product != null && routeProductMatch(allRoutes[i].meta))) {
        result.push(allRoutes[i]);
      }
    }
  }
  return result;
}

export function allowedHeaderRoutes():Array<RouteRecordPublic> {
  return allowedRoutes(true);
}

export function allowedRailRoutes():Array<RouteRecordPublic> {
  return allowedRoutes(false);
}

// global route guard for product access and psuedo-SSO
router.beforeEach(async (to, from, next) => {
  if (to.meta != null && to.meta.handleQueryParams != null && to.query != null && Object.keys(to.query).length > 0) {
    await to.meta.handleQueryParams(to, next);
  }
  if (to.meta != null && to.meta.handleHashParams != null && to.hash !== null && to.hash !== '') {
    await to.meta.handleHashParams(to, next);
  }
  const qsTokenKey = getQueryParamKeyCaseInsensitive(to.query, ['token']);
  if (qsTokenKey != null && to.query[qsTokenKey] != null) {
    if (isTokenExpired(to.query[qsTokenKey] as string)) {
      next('/404');
      return;
    }
    await bootstrapLogin(to.query[qsTokenKey] as string);
    await setAgent();
    const cleanQuery = cloneDeep(to.query);
    delete cleanQuery[qsTokenKey];
    router.replace({
      query: cleanQuery,
      path: to.path,
    });
    next();
    return;
  }
  if (to.meta != null && to.meta.secured != null && to.meta.secured === true && !globalStore.getters['auth/isLoggedIn']) {
    globalStore.dispatch('auth/logout');
    next({
      path: loginRedirectUrl(document.location.pathname, document.location.search),
      replace: true,
    });
    return;
  }
  // does this route have a product defined?
  if (to.meta != null && to.meta.product != null) {
    const productAccess = globalStore.getters['auth/permissions'].ProductAccess;
    if (productAccess == null) {
      // missing or incomplete session.. return to login
      globalStore.dispatch('auth/logout');
      next({
        path: loginRedirectUrl(document.location.pathname, document.location.search),
        replace: true,
      });
      return;
    }
    if (!routeProductMatch(to.meta)) {
      next({
        path: '/404',
        replace: true,
      });
      return;
    }
  }
  // does this route have roles defined?
  if (to.meta != null && to.meta.roles != null) {
    if (!routeRoleMatch(to.meta.roles)) {
      next({
        path: '/404',
        replace: true,
      });
      return;
    }
  }
  next();
});

router.afterEach((to, from) => {
  let title = 'App';
  if (to.meta != null && to.meta.pageTitle != null) {
    title = to.meta.pageTitle;
  }
  Vue.nextTick(() => {
    document.title = process.env.VUE_APP_TITLE_TEMPLATE!.replace('%s', title);
    if (globalStore.getters['conversations/unreadGroupChatIds'].length > 0) {
      blinkTitle('New Message');
    }
  });
});

export default router;
